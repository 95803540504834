const Sticky = require("sticky-js");

exports.initializeSticky = () => {
  setSticky();

  window.addEventListener("resize", () => {
    setSticky();
  });
};

const setSticky = () => {
  if (window.innerWidth >= 1000) {
    new Sticky(".sticky");
  }
};
