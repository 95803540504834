const { Chart, registerables } = require("chart.js");

Chart.register(...registerables);

let chart1 = null;
let chart2 = null;

exports.bindCalculatorMobile = () => {
  const calculatorCosts = document.getElementById("calculatorCostsMobile");
  const calculatorHours = document.getElementById("calculatorHoursMobile");
  const calculatorRevenues = document.getElementById(
    "calculatorRevenuesMobile",
  );
  const calculatorDays = document.getElementById("calculatorDaysMobile");

  if (
    calculatorCosts &&
    calculatorHours &&
    calculatorRevenues &&
    calculatorDays
  ) {
    calculateCostsMobile(
      calculatorCosts,
      calculatorHours,
      calculatorRevenues,
      calculatorDays,
    );

    calculatorHours.addEventListener("change", () => {
      calculateCostsMobile(
        calculatorCosts,
        calculatorHours,
        calculatorRevenues,
        calculatorDays,
      );
    });

    calculatorRevenues.addEventListener("change", () => {
      calculateCostsMobile(
        calculatorCosts,
        calculatorHours,
        calculatorRevenues,
        calculatorDays,
      );
    });

    calculatorDays.addEventListener("change", () => {
      calculateCostsMobile(
        calculatorCosts,
        calculatorHours,
        calculatorRevenues,
        calculatorDays,
      );
    });
  }
};

/**
 * @param calculatorCosts {HTMLElement}
 * @param calculatorHours {HTMLElement}
 * @param calculatorRevenues {HTMLElement}
 * @param calculatorDays {HTMLElement}
 */
const calculateCostsMobile = (
  calculatorCosts,
  calculatorHours,
  calculatorRevenues,
  calculatorDays,
) => {
  const delay = ((0.5 * calculatorDays.value) / 360) * calculatorRevenues.value;
  const workload = 480 * calculatorHours.value;
  const costs = (delay + workload + 0.008 * calculatorRevenues.value) / 12;

  calculatorCosts.innerHTML = `
        ${new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(costs)}
        <span class="small text-secondary">/ mois</span>
    `;

  if (chart1) {
    chart1.destroy();
  }

  if (chart2) {
    chart2.destroy();
  }

  if (costs < 150) {
    drawCalculatorChartsMobile("green");
  } else if (costs >= 150 && costs < 500) {
    drawCalculatorChartsMobile("yellow");
  } else {
    drawCalculatorChartsMobile("red");
  }
};

/** @param cursorColor {string} */
const drawCalculatorChartsMobile = (cursorColor) => {
  const chartJSContainerMobile = document.getElementById(
    "chartJSContainerMobile",
  );

  if (chartJSContainerMobile) {
    const options1 = {
      type: "doughnut",
      data: {
        labels: ["Red", "Orange", "Green"],
        datasets: [
          {
            label: "",
            data: [33, 33, 33],
            backgroundColor: [
              "rgba(46, 204, 113, 1)",
              "rgba(255, 164, 46, 1)",
              "rgba(231, 76, 60, 1)",
            ],
            borderColor: [
              "rgba(255, 255, 255 ,1)",
              "rgba(255, 255, 255 ,1)",
              "rgba(255, 255, 255 ,1)",
            ],
            borderWidth: 5,
          },
        ],
      },
      options: {
        cutoutPercentage: 30,
        rotation: -90,
        circumference: 180,
        legend: {
          display: false,
        },
        labels: {
          display: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    };

    const ctx1 = chartJSContainerMobile.getContext("2d");
    chart1 = new Chart(ctx1, options1);
  }

  const secondContainerMobile = document.getElementById(
    "secondContainerMobile",
  );

  if (secondContainerMobile) {
    let data;

    if (cursorColor === "red") {
      data = [83, 1, 16];
    } else if (cursorColor === "yellow") {
      data = [50, 1, 49];
    } else {
      data = [16, 1, 83];
    }

    const options2 = {
      type: "doughnut",
      data: {
        labels: ["", "", ""],
        datasets: [
          {
            data,
            backgroundColor: [
              "rgba(0, 0, 0, 0)",
              "rgba(139, 125, 114, 1)",
              "rgba(0, 0, 0, 0)",
            ],
            borderColor: [
              "rgba(0, 0, 0 ,0)",
              "rgba(139, 125, 114, 1)",
              "rgba(0, 0, 0 ,0)",
            ],
            borderWidth: 3,
          },
        ],
      },
      options: {
        cutoutPercentage: 95,
        rotation: -90,
        circumference: 180,
        legend: {
          display: false,
        },
        labels: {
          display: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    };

    const ctx2 = secondContainerMobile.getContext("2d");
    chart2 = new Chart(ctx2, options2);
  }
};
