require("../../vendors/slick/slick.min.js");
require("../../../node_modules/aos/dist/aos.css");
require("./scripts/scripts");

require("../../vendors/slick/slick.css");
require("../../vendors/slick/slick-theme.css");
require("../../../node_modules/aos/dist/aos");
require("../../../node_modules/chart.js/dist/chart");
require("../../../node_modules/sticky-js/dist/sticky.min");
require("./styles/styles.scss");
