const randomize = (start, end, round) => {
  if (round === true) {
    return Math.round(Math.floor(Math.random() * end) + start);
  } else {
    return Math.random() * end + start;
  }
};

const beam = (num) => {
  const beamNum = num;
  const scene = document.getElementById("scene");

  if (scene) {
    const colors = ["#8B7D72", "#8B7D72"];
    scene.innerHTML = "";

    for (let i = 0; i < beamNum; i++) {
      const beam = document.createElement("span");
      beam.classList.add("light-beam");
      beam.style.left = randomize(1, 100, true) + "%";
      beam.style.width = randomize(100, randomize(0, 400, true), true) + "px";
      beam.style.height = randomize(0, randomize(0, 100, true), true) + "%";
      beam.style.opacity = randomize(0, 0.3, false);
      beam.style.background =
        "linear-gradient(rgba(" +
        colors[Math.round(Math.random() * 1)] +
        ", " +
        randomize(0, 0.5, false) +
        "), rgba(255,255,255, 0))";
      scene.appendChild(beam);
    }
  }
};

exports.initializeLightAnimation = () => {
  window.onload = () => {
    beam(20);
  };

  window.onclick = () => {
    beam(20);
  };
};
