/** @returns {void} */
exports.initializeTarteAuCitron = () => {
  tarteaucitron.init({
    privacyUrl: "https://df-recouvrement.fr/mentions-legales",
    hashtag: "#tarteaucitron",
    cookieName: "tarteaucitron",
    orientation: "middle",
    showAlertSmall: false,
    cookieslist: true,
    adblocker: false,
    AcceptAllCta: true,
    highPrivacy: true,
    handleBrowserDNTRequest: false,
    removeCredit: true,
    moreInfoLink: true,
    useExternalCss: false,
    readmoreLink: "https://df-recouvrement.fr/mentions-legales",
  });

  window.tarteaucitronForceLanguage = "fr";

  (tarteaucitron.job = tarteaucitron.job || []).push("matomo");
};
