const $ = require("jquery");

exports.initializeHeroSlider = () => {
  const sliderFor = $(".slider-for");
  const sliderNavConfiguration = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    centerMode: true,
    focusOnSelect: true,
    prevArrow: $(".prev"),
    nextArrow: $(".next"),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (sliderFor && sliderFor.length > 0) {
    sliderFor.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: ".slider-nav",
      autoplay: true,
      autoplaySpeed: 2500,
      infinite: true,
    });

    sliderNavConfiguration.asNavFor = ".slider-for";
  }

  $(".slider-nav").slick(sliderNavConfiguration);
};
