exports.bindLegalLink = () => {
  const legalLink = document.getElementById("legalLink");

  if (legalLink) {
    legalLink.addEventListener("click", (event) => {
      event.preventDefault();

      const from = new URLSearchParams(window.location.search).get("from");
      return (window.location.href = from
        ? from === "customer"
          ? "/fr/accueil"
          : "/fr/conseils-gestion-creances"
        : "/fr/accueil");
    });
  }
};
