const { initializeHeroSlider } = require("./slider/hero-slider");
const { initializeAos } = require("./aos/aos-loader");
const { initializeSticky } = require("./sticky/sticky-loader");
const { initializeLightAnimation } = require("./animation/light-animation");
const { bindCalculator } = require("./calculator/calculator");
const { bindLegalLink } = require("./link/legal-link");
const { bindCalculatorMobile } = require("./calculator/calculator-mobile");
const { initializeTarteAuCitron } = require("./loader/tarteaucitron-loader");

document.addEventListener("DOMContentLoaded", () => {
  // Loaders
  initializeHeroSlider();
  initializeAos();
  initializeLightAnimation();
  initializeSticky();
  initializeTarteAuCitron();

  // Bindings
  bindCalculator();
  bindCalculatorMobile();
  bindLegalLink();
});
